import React, { Fragment, useEffect } from 'react';
import { Popover, Transition, Dialog } from '@headlessui/react';
import { Button } from '@tradesorg/component-library-waas-templates.base-button';
import { InputDropdown } from '@tradesorg/component-library-waas-templates.base-input-dropdown';
import { InputTemplate } from '@tradesorg/component-library-waas-templates.base-input-template';
import { XIcon } from '@heroicons/react/outline';

import './index.scss';

const RequestMeetingModal = ({openRequestMeeting, setOpenRequestMeeting, urlHubspot, props}) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    });
    return (
        <Transition.Root show={openRequestMeeting} as={Fragment}>
        <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={openRequestMeeting}
            onClose={setOpenRequestMeeting}
        >
            <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className="inline-block bg-white w-screen h-screen  2xl:w-624px 2xl:h-864px 3wl:w-624px 3wl:h-824px text-left overflow-hidden 
                    shadow-xl transform transition-all smy-8 align-middle">
                        <div className="ml-0 flex gird justify-end xs:pr-10px sm-1:pr-6 xs:pt-10px sm-1:pt-37px">
                            <button
                                type="button"
                                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none p-0"
                                onClick={() => setOpenRequestMeeting(false)}
                            >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-30px w-30px" aria-hidden="true" />
                            </button>
                        </div>
                        {/* <div className="flex flex-col items-center">
                            <iframe src={urlHubspot} className="template-3-meeting-modal w-screen h-screen xs:pt-10px xs:pl-10px xs:pb-10px xs:pr-10px 
                            sm-1:pt-39px sm-1:pb-47px sm-1:pl-18px sm-1:pr-18px  2xl:w-624px 2xl:h-864px 3wl:w-624px 3wl:h-824px"/>
                        </div> */}
                          <div
                            className="w-screen h-screen xs:pt-10px xs:pl-10px xs:pb-10px xs:pr-10px 
                            sm-1:pt-39px sm-1:pb-47px sm-1:pl-18px sm-1:pr-18px  2xl:w-624px 2xl:h-864px meetings-iframe-container bg-white container-calendar "
                            data-src={urlHubspot}
                        ></div>
                    </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition.Root>
    );
};

export default RequestMeetingModal;
